import React from 'react';
import { themr } from '@friendsofreactjs/react-css-themr';
import { useStore } from 'hooks/index';
import { uniq } from 'lodash';

import Select from 'components/layout/Select';
import SearchIcon from 'images/icons/searchGlass.svg';

import styles from './styles.scss';

interface Props {
  theme?: Theme;
}

const Location = ({ theme }: Props) => {
  const accommodationStore = useStore('accommodationStore');

  function handleChange(_: any, value: string) {
    accommodationStore.location = value;
  }

  return (
    <div className={theme!.locationFilter}>
      <h6>Where would you like to go?</h6>
      <Select
        placeholder="e.g., Albany, WA"
        name="location"
        options={uniq(accommodationStore.locations.sort())}
        callback={handleChange}
        length={180}
        defaultValue={accommodationStore.location}
        filter
      >
        <SearchIcon />
      </Select>
    </div>
  );};

export default  themr('LocationFilter', styles)(Location);
