import React,{ useState } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import { useStore, useFiltersCleared } from 'hooks/index';
import { themr } from '@friendsofreactjs/react-css-themr';
import { format } from 'date-fns';
import { observer } from 'mobx-react';

import DatePicker from 'react-datepicker';
import { addDays, isAfter, differenceInCalendarDays } from 'date-fns';

import CheckIn from 'images/icons/check-in.svg';
import CheckOut from 'images/icons/check-out.svg';

import styles from './styles.scss';

interface Props {
  theme?: Theme;
}

interface State {
  from?: Date;
  to?: Date;
}

const Dates = ({ theme }: Props) => {
  const accommodationStore = useStore('accommodationStore');
  const [inputs, setDateInput] = useState<State>({ from: undefined, to: undefined });
  useFiltersCleared(() => setDateInput({ from: undefined, to: undefined }));
  useIsomorphicLayoutEffect(() => {
    if( (!inputs.from && !inputs.to)
      && (accommodationStore.checkin && accommodationStore.checkout)
    ) {
      setDateInput({ from: new Date(accommodationStore.checkin), to: new Date(accommodationStore.checkout)});
    }
  },[accommodationStore, accommodationStore.checkin, inputs]);

  function handleDateChange (date: Date, type: string) {
    switch (type) {
      case 'from':
        let to = inputs.to || new Date();
        // ensure to date is at least 2 days after from date
        if (differenceInCalendarDays(date, to) >= -1) {
          to = new Date(addDays(date, 2));
        }
        setDateInput({from: date, to });
        accommodationStore.setBookingDates(format(new Date(date), 'yyyy-MM-dd'), format(new Date(to), 'yyyy-MM-dd'));
        break;
      case 'to':
        const from = inputs.from || addDays(new Date(), 2);
        if (isAfter(date, from)) {
          setDateInput({ ...inputs, to: date });
          accommodationStore.setBookingDates(format(new Date(from), 'yyyy-MM-dd'), format(new Date(date), 'yyyy-MM-dd'));
        }
        break;
      default:
        break;
    }
  }

  return (
    <div className={theme!.dateInput}>
      <div className={theme!.titles}>
        <h6>Check in</h6>
        <h6>Check out</h6>
      </div>
      <div className={theme!.dates}>
        <span className={theme!.checkin}>
          <DatePicker
            selected={inputs.from}
            placeholderText="check in date"
            onChange={(date: Date) => handleDateChange(date, 'from')}
            selectsStart
            minDate={addDays(new Date(), 1)}
            startDate={inputs.from}
            dateFormat={"dd/MM/yyyy"}
          />
          <CheckIn/>
        </span>
        <span className={theme!.checkout}>
          <DatePicker
            selected={inputs.to}
            placeholderText="check out date"
            onChange={(date: Date) => handleDateChange(date, 'to')}
            selectsEnd
            minDate={inputs.from}
            dateFormat={"dd/MM/yyyy"}
          />
          <CheckOut />
        </span>
      </div>
    </div>
  );
};

export default themr('DateInput', styles)(observer(Dates));
